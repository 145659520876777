import React from 'react';


const certComponent = (props) => {

    const htmlSkillImage = props.info.map((name,index) =>
    <div className="skill-img-div" key={index}><a href={name.link} className="teal-text hoverline" target = "_blank" rel = "noopener noreferrer"><img alt="" src={name.image} className="responsive-img" key={index} />{name.name}</a></div>
    );


    return (
        <div className="card">
                <div className="card-content">
                    <h4 className="brown-text light">{props.title}</h4>
                    <div className="row text-center">
                        {htmlSkillImage}
                    </div>
                </div>
            </div>
    );

};

export default certComponent;