import React from 'react';


const skillComponent = (props) => {



    return (
        <div >
            <div className="ImportantHeader">
                <h1>Error Page</h1>
            </div>
            <div className="AboutMeDiv">
            <h1>This is a 404 page.</h1>
            <p>Hello there General Kenobi, There are no hidden pages on this site.
                Perhaps you got here by mistake. <br></br>Feel free to visit the main links on the main navigation bar.
            </p>
            </div>
        </div>
    );

};

export default skillComponent;