import React from 'react';


const basicCard = (props) => {

    return (
        <div className="card">
                <div className="card-content">
                    <p><span className="card-title"><a href={props.info.link} className="teal-text hoverline" target = "_blank" rel = "noopener noreferrer">{props.info.title}</a></span></p>
                    <p className="brown-text">{props.info.location}</p>
                    {props.children}
                </div>
            </div>
    );

};

export default basicCard;