import React from 'react';


import Slider from '../PageComponent/Slider/Slider';

import FirstImage from '../../../assets/images/SliderImages/1.jpg';
import SecondImage from '../../../assets/images/SliderImages/2.jpg';
import ThirdImage from '../../../assets/images/SliderImages/3.jpg';
import FithImage from '../../../assets/images/SliderImages/4.jpg';



const aboutPage = (props) => {

    const SliderProps = [
        
        { image: FirstImage, alt: "First Slide" , label:"My wife, Ashlin and I",underlabel: "We are currently located in Hawaii" },
        { image: SecondImage, alt: "Second Slide" , label:"She said yes",underlabel: "We were married in San Diego" },
        { image: ThirdImage, alt: "Second Slide" , label:"I graduated in Computer Science",underlabel: "From Brigham Young University Hawaii" },
        { image: FithImage, alt: "Second Slide" , label:"Sometimes we go scuba diving",underlabel: "This is one of my many hobbies" },
    ]

   
    return (
        <div>
           
           <div className="ImportantHeader">
                <h1>About Me</h1>
            </div>
           <Slider info={SliderProps} />
           
            

        </div>

    )
};

export default aboutPage;