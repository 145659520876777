import React from 'react';



import SideNavBar from '../Navigation/SideNavBar/SideNavBar';

import TopNavBar from '../Navigation/TopNavBar/TopNavBar';

const layout = (props) => {

        return (

            <div >
                <SideNavBar />
                <TopNavBar />
                {props.children}
            </div>
        )
};

export default layout;