import React from 'react';


const skillComponent = (props) => {

    const htmlSkillImage = props.info.map((name,index) =>
    <div className="skill-img-div" key={index}><img alt="" src={name.image} className="responsive-img" key={index} />{name.name}</div>
    );


    return (
        <div className="card">
                <div className="card-content">
                    <h4 className="brown-text light">{props.title}</h4>
                    <div className="row text-center">
                        {htmlSkillImage}
                    </div>
                </div>
            </div>
    );

};

export default skillComponent;