import React from 'react';

// import { Link } from 'react-router-dom';


import BackGroundImage from '../../../assets/images/water.jpg';

import ByuhPic from '../../../assets/images/byuh.png';
import IboatsPic from '../../../assets/images/iboats.png';



import JavascriptPic from '../../../assets/images/javascript.svg';
import ReactPic from '../../../assets/images/react.png';
import nodejsPic from '../../../assets/images/nodejs.png';
import JqueryPic from '../../../assets/images/jquery.svg';

import dockerPic from '../../../assets/images/docker.png';
import dotnetPic from '../../../assets/images/dotnet.png';
import gitPic from '../../../assets/images/git.svg';
import javaPic from '../../../assets/images/java.svg';
import mysqlPic from '../../../assets/images/mysql.png';
import pythonPic from '../../../assets/images/python.png';

import awsArchitectPic from '../../../assets/images/aws-architect.png';


import ExperienceComponent from '../PageComponent/ExperienceComponent/ExperienceComponent';

import SkillsComponent from '../PageComponent/SkillComponent/SkillComponent';


import BasicCard from '../PageComponent/BasicCard/BasicCard';
import CertComponent from '../PageComponent/CertComponent/CertComponent';


const home = (props) => {



    


    const ExperienceComponentProps = {
        link: "http://www.iboats.com",
        companyImage: IboatsPic,
        companyName: "iBoats",
        title: "Cross Stack Developer",
        underTitleText: "The Responsibilities of a Cross Stack Developer is to be able to create solutions for any framework or codebase maintained by iBoats.",
        endingText: "September 2019 - Present | Taylorsville, UT"
    };
    const ExperienceComponentnumber2Props = {
        link: "http://www.byuh.edu",
        companyImage: ByuhPic,
        companyName: "BYU-Hawaii",
        title: "Software Developer",
        underTitleText: "The role of the Enterprise Information Systems department is to develop software solutions to meet the needs of the campus base of customers.",
        endingText: "March 2018 - September 2019 | Laie, HI"
    };

    const javaScriptSkillComponentImages = [
        { name: "JavaScript", image: JavascriptPic },
        { name: "JQuery", image: JqueryPic },
        { name: "Node JS", image: nodejsPic },
        { name: "React", image: ReactPic },
    ]

    const ProgrammingSkillComponentImages = [
        { name: "C# / .Net", image: dotnetPic },
        { name: "Docker", image: dockerPic },
        { name: "Git", image: gitPic },
        { name: "Java", image: javaPic },
        { name: "MySQL", image: mysqlPic },
        { name: "Python", image: pythonPic },
    ]

    const BasicCardProps = {
        link : "http://www.byuh.edu",
        title : "Brigham Young University - Hawaii",
        location : "Laie, HI"
    };

    const AWSCertComponentImages = [
        { name: "AWS Certified Solutions Architect – Associate", image: awsArchitectPic, link: "https://www.youracclaim.com/badges/9613f90a-aff7-4555-b0b0-65dd9115e0b1/public_url" },
    ]


    return (
        <div>
            <div style={{
                backgroundImage: "url(" + BackGroundImage + ") ",
                width: "100%",
                height: "600px",
                color: "white"
            }} className="Homepage-div-Image">
                <h1>I'm Scott, a Software Developer<br></br> ready to make a difference in your company</h1>
            </div>
            <div className="ImportantHeader">
                <h1>About Me</h1>
            </div>
            <div className="AboutMeDiv">
                <h1>Divergent thinking, creativity, and a passion for innovation has led me to a career in software development</h1>
                <p>I seek to further my passion for development and a place to apply my skills. I am a well-rounded developer,
                     with experience ranging from game to web development. One of my strongest skills is problem solving. 
                     I'm always searching for more efficient ways to implement improvements while simplifying processes.
                      I am constantly looking for creative ways of performing duties and solving issues. I strive to be on the leading edge of technology,
                       this means learning and applying innovative software as it becomes standardized.

                </p>
            </div>
            <div className="ImportantHeader">
                <h1>Experience</h1>
            </div>
            <ExperienceComponent
                information={ExperienceComponentProps}
            >
                <h6>Develop Products</h6>
                <ul>
                    <li>Troubleshoot current AWS infrastructure</li>
                    <li>Work on using CloudFlare as CDN and DDOS protection for iboats.com</li>
                    <li>Set-up AWS WAF and ACL as bot mitigation</li>
                    <li>Fixed issues on Magento Platform for better stability for customers</li>
                    <li>Integrate custom solutions from vBulletin to Xenforo</li>
                    <li>Implement a Static site that is downloaded during build via S3 to keep Image lean</li>
                    <li>Develop Python Cron to check hashes on Directory or files that integrates with AWS CLI</li>
                    <li>Fixed issues on Drupal 7</li>
                    <li>Created a way to display data meanifully on React application</li>
                    <li>Manage local user and roles via Redux,Redux Sagas and GraphQL</li>

                    
                </ul>
                <h6>Accomplishments</h6>
                <ul>
                    <li>Acting office IT Administrator</li>
                    <li>Implement ECS Cluster for Middleware</li>
                    <li>Fix AWS ECS issues and Codepipeline problems</li>
                    <li>Migrate from vBulletin to Xenforo</li>
                    <li>Protected vBulletin site by setting up a solution with AWS ECS and Python</li>
                    <li>Created a more stable environment for Drupal while making sure everything was up to date.</li>
                    <li>Created React Application to be used internally.</li>
                </ul>
            </ExperienceComponent>
            <ExperienceComponent
                information={ExperienceComponentnumber2Props}
            >
                 <h6>Develop Products</h6>
                <ul>
                    <li>Moved about 100 sites into static sites</li>
                    <li>Moved static sites into Git repos/deployed on server</li>
                    <li>Created Python scripts to help maintain updates for static sites</li>
                    <li>Set up temporary Drupal 8 server to use while switching over to Drupal 8</li>
                    <li>Developed JavaScript/JQuery custom pages on <a href="https://holokai.byuh.edu">Holokai</a> site</li>
                    <li>Worked on Drupal 8 sites</li>
                    <li>Develop Directory in PHP/HTML/CSS</li>
                    <li>Develop Directory in .NET/Docker</li>
                    <li>Worked on application in Docker (PHP, Python)</li>
                    <li>Worked on exisitng Java(Spring) applications</li>
                    <li>Created student development environment to help with Local development</li>
                    
                    
                </ul>
                <h6>Accomplishments</h6>
                <ul>
                    <li>Moved Drupal sites from un-patched server's to static server, which increased reliability while switching over to the new system.</li>
                    <li>Setup a Git system so that users could push edits and it would show on the website, allowing edits to be made in a timely manner while still restricting access on the server.</li>
                    <li>Created custom Python scripts to boost efficiency of edits.</li>
                    <li>Created a custom in house JavaScript/JQuery script to give functionality not provided by Drupal.</li>
                    <li>Worked on containerizing applications to make them more efficient.</li>

                </ul>
            </ExperienceComponent>
            <a href="/experience" className="homepage-link">
            <div className="LinkToPage">
                Click here to learn more about my Experience
            </div>
            </a>
            <div className="ImportantHeader">
                <h1>Skills</h1>
            </div>

            <SkillsComponent
                title="JavaScript"
                info={javaScriptSkillComponentImages}>
            </SkillsComponent>

            <SkillsComponent
                title="Programming"
                info={ProgrammingSkillComponentImages}>
            </SkillsComponent>
            <a href="/skills" className="homepage-link">
            <div className="LinkToPage">
                Click here to learn more about my Skills
            </div>
            </a>

            <div className="ImportantHeader">
                <h1>Certification's</h1>
            </div>
            <br></br>

            <CertComponent
                title="Amazon Web Services"
                info={AWSCertComponentImages}>
            </CertComponent>

            <div className="ImportantHeader">
                <h1>Education</h1>
            </div>
            <br></br>

            <BasicCard
                info={BasicCardProps}
                >
                <ul>
                    <li>B.A. in Computer Science</li>
                    <li>Minor In Information Technology</li>
                </ul>
            </BasicCard>

            
            

        </div>

    )
};

export default home;