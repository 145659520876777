import React from 'react';

import ExperienceComponent from '../ExperienceComponentSecond/ExperienceComponentSecond';

const experienceSection = (props) => {


    return (
        <li className="timeline_element project">
            <div className="timeline_element-date">
                <time className="timeline_element-date-text">{props.information.timeline}</time>
            </div>

            <div className="timeline_element-contents">
                <div className="project-text">

                    <div className="project-technologies">
                        <ExperienceComponent
                            information={props.information}
                        >
                            {props.children}
                        </ExperienceComponent>
                    </div>
                </div>




            </div>
        </li>
    );

};

export default experienceSection;