import React from 'react';

import Logo from '../../assets/images/scott.jpg';

const logo = (props) => (
    <div >
        <a href="/">
        <div className="Logo"  style={{
                background: "url(" + Logo + ") 90% 50% / cover no-repeat"}}
                 alt="Logo" > </div>
                 </a>
    </div>
);

export default logo;