import React from 'react';
import { Route } from 'react-router-dom';

import HomePage from '../../components/Pages/HomePage/HomePage';

import ExperiencePage from '../../components/Pages/ExperiencePage/ExperiencePage';

import SkillsPage from '../../components/Pages/SkillsPage/SkillsPage';

import AboutPage from '../../components/Pages/AboutPage/AboutPage';

import ContactPage from '../../components/Pages/ContactPage/ContactPage';

import ErrorPage from '../../components/Pages/ErrorPage/ErrorPage';






const content = (props) => {

    return (

        <div className="content" >
            <Route path="/"  exact component={HomePage} />
            <Route path="/experience"  exact component={ExperiencePage} />
            <Route path="/skills"  exact component={SkillsPage} />
            <Route path="/aboutme"  exact component={AboutPage} />
            <Route path="/contact"  exact component={ContactPage} />
            <Route path="/error"  exact component={ErrorPage} />
        </div>


    )

};

export default content;