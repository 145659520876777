import React from 'react';

import Github from '../../../assets/images/github.svg';
import Email from '../../../assets/images/email-black.png';
import Resume from '../../../assets/images/resume-black.png'
import Linkedin from '../../../assets/images/linkdin.svg';

import ResumeLink from '../../../assets/Resume/Web Resume Scott Cooper.pdf';



const contactPage = (props) => {


    return (
        <div>

            <div className="ImportantHeader">
                <h1>Contact Me</h1>
            </div>
            <div className="card contactme">
                <div className="card-content">
                    <h4 className="">Contact Me</h4>
                    <div className="row text-center">

                    </div>

                    <div className="icon-set">
                        <a href="mailto:sgcooper78@gmail.com"><img alt="Email Icon" src={Email}  /><h3>sgcooper78@gmail.com</h3></a>
                        <a href="https://github.com/sgcooper78" without rel="noopener noreferrer"><img alt="Github Icon" src={Github} /><h3>Github</h3></a>
                        <a href="https://www.linkedin.com/in/sgcooper78/" without rel="noopener noreferrer"><img alt="Linkedin Icon" src={Linkedin} /><h3>Linkedin</h3></a>
                        <a href={ResumeLink} target="_blank" without rel="noopener noreferrer"><img alt="Resume Icon" src={Resume} /><h3>Resume</h3></a>


                    </div>


                </div>

            </div>



        </div>

    )
};

export default contactPage;