import React from 'react';

import SkillsComponent from '../PageComponent/SkillComponent/SkillComponent';

import HtmlPic from '../../../assets/images/html5.png';
import cssPic from '../../../assets/images/css.svg';
import sassPic from '../../../assets/images/sass.png';
import bootstrapPic from '../../../assets/images/bootstrap.png';

import JavascriptPic from '../../../assets/images/javascript.svg';
import ReactPic from '../../../assets/images/react.png';
import nodejsPic from '../../../assets/images/nodejs.png';
import JqueryPic from '../../../assets/images/jquery.svg';

import dockerPic from '../../../assets/images/docker.png';
import dotnetPic from '../../../assets/images/dotnet.png';
import gitPic from '../../../assets/images/git.svg';
import javaPic from '../../../assets/images/java.svg';
import mysqlPic from '../../../assets/images/mysql.png';
import pythonPic from '../../../assets/images/python.png';


import androidstudioPic from '../../../assets/images/androidstudio.png';
import flutterPic from '../../../assets/images/flutter.png';

import unityPic from '../../../assets/images/unity.png';
import unrealPic from '../../../assets/images/unreal.png';

import phpPic from '../../../assets/images/php.png';
import drupalPic from '../../../assets/images/drupal.svg';
import amazonawsPic from '../../../assets/images/amazonaws.png';
import wordpressPic from '../../../assets/images/wordpress.svg';
import magentoPic from '../../../assets/images/magento.png';
import xenforoPic from '../../../assets/images/xenforo.png';
import vbulletinPic from '../../../assets/images/vbulletin.svg';


import photoshopPic from '../../../assets/images/photoshop.png';
import illustratorPic from '../../../assets/images/illustrator.png';
import spanishPic from '../../../assets/images/spanish.png';
import linuxPic from '../../../assets/images/linux.png';





const skillsPage = (props) => {

    const HtmlSkillComponentImages = [
        { name: "HTML5", image: HtmlPic },
        { name: "CSS", image: cssPic },
        { name: "SASS", image: sassPic },
        { name: "Bootstrap", image: bootstrapPic },
        { name: "React", image: ReactPic }
    ]

    const javaScriptSkillComponentImages = [
        { name: "JavaScript", image: JavascriptPic },
        { name: "JQuery", image: JqueryPic },
        { name: "Node JS", image: nodejsPic },
        { name: "React", image: ReactPic },
    ]

    const ProgrammingSkillComponentImages = [
        { name: "C# / .Net", image: dotnetPic },
        { name: "Docker", image: dockerPic },
        { name: "Git", image: gitPic },
        { name: "Java", image: javaPic },
        { name: "MySQL", image: mysqlPic },
        { name: "Python", image: pythonPic },
    ]

    const MobileSkillComponentImages = [
        { name: "Android Studio", image: androidstudioPic },
        { name: "Dart and Flutter", image: flutterPic },
        { name: "React", image: ReactPic }
    ]

    const GameEngineSkillComponentImages = [
        { name: "Unity", image: unityPic },
        { name: "Unreal", image: unrealPic }
    ]

    const WebSkillComponentImages = [
        
        { name: "Amazon AWS", image: amazonawsPic },
        { name: "Drupal", image: drupalPic },
        { name: "Magento", image: magentoPic },
        { name: "PHP", image: phpPic },
        { name: "WordPress", image: wordpressPic },
        { name: "Vbulletin", image: vbulletinPic },
        { name: "Xenforo", image: xenforoPic },

    ]

    const OtherSkillComponentImages = [
        { name: "Adobe Photoshop", image: photoshopPic },
        { name: "Adobe Illustrator", image: illustratorPic },
        { name: "Spanish", image: spanishPic },
        { name: "Linux", image: linuxPic },
    ]

    return (
        <div>
           
           <div className="ImportantHeader">
                <h1>Skills</h1>
            </div>
           <SkillsComponent
                title="HTML"
                info={HtmlSkillComponentImages}>
            </SkillsComponent>

            <SkillsComponent
                title="JavaScript"
                info={javaScriptSkillComponentImages}>
            </SkillsComponent>

            <SkillsComponent
                title="Programming"
                info={ProgrammingSkillComponentImages}>
            </SkillsComponent>

            <SkillsComponent
                title="Mobile"
                info={MobileSkillComponentImages}>
            </SkillsComponent>

            <SkillsComponent
                title="Game Engine"
                info={GameEngineSkillComponentImages}>
            </SkillsComponent>

            <SkillsComponent
                title="Web"
                info={WebSkillComponentImages}>
            </SkillsComponent>

            <SkillsComponent
                title="Other"
                info={OtherSkillComponentImages}>
            </SkillsComponent>
            

        </div>

    )
};

export default skillsPage;