import React from 'react';

// import { Link } from 'react-router-dom';

import Logo from '../../Logo/Logo';

import Github from '../../../assets/images/github.png';
import Email from '../../../assets/images/email.png';
import Resume from '../../../assets/images/resume.png'
import Linkedin from '../../../assets/images/linkdin.png';

import ResumeLink from '../../../assets/Resume/Web Resume Scott Cooper.pdf';


const topNavBar = (props) => {


    return (
        <div>
            
            <nav className="topbarnav navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
            <div className="topbarnav-icon-set">
                <div>Contact Me</div>
                                <a href="mailto:sgcooper78@gmail.com"><img alt="Email Icon" src={Email} /></a>
                                <a href="https://github.com/sgcooper78" target="_blank"  rel="noopener noreferrer"><img alt="Github Icon" src={Github} /></a>
                                <a href="https://www.linkedin.com/in/sgcooper78/" target="_blank"  rel="noopener noreferrer"><img alt="Linkedin Icon" src={Linkedin} /></a>
                                <a href={ResumeLink} target="_blank"  rel="noopener noreferrer"><img alt="Resume Icon" src={Resume} /></a>


                            </div>
                <div className="topbarnav-header">
                    <div className="topbarnav-info">
                    <Logo />
                    <div>
                    <h3 className="h3topBarNav">Scott Cooper</h3>
                    <p>Software Developer</p>
                    </div>
                    </div>

                    <div className="topbarnav-under-header">
                        
                                {/* <Link to="/" data-toggle="collapse" aria-expanded="false" >Home / Portfolio</Link> */}
                                <a href="/">Home / Portfolio</a>

                                <a href={ResumeLink} target="_blank"  rel="noopener noreferrer">Resume</a>
                           
                                {/* <Link to="/experience" >Experience</Link> */}
                                <a href="/experience">Experience</a>
                           
                                {/* <Link to="/skills" >Skills</Link> */}
                                <a href="/skills" >Skills</a>
                           
                                {/* <Link to="/aboutme" >About Me</Link> */}
                                <a href="/aboutme">About Me</a>
                           
                                {/* <Link to="/contact" >Contact</Link> */}
                                <a href="/contact">Contact</a>
                           
                            
                    

                    </div>
                    

                </div>





            </nav>
        </div>
    );
};

export default topNavBar;