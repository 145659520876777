import React from 'react';

import { BrowserRouter } from 'react-router-dom'

import Layout from './components/Layout/Layout';

import Content from './containers/Content/Content';

import './App.css';




function App() {
  return (
    <div >
      <BrowserRouter>
      <Layout >
      <Content />

      </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
