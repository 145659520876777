import React , {Component} from "react";
import Slider from "react-slick";

class SliderComponent extends Component {
  render() {
    const  CarouselItems = this.props.info.map((name,index) =>
    <div className="SlideImage" key={index}>
            <img alt={name.alt} src={name.image} />
            <h3 >{name.label}</h3>
              <p>{name.underlabel}</p>
    </div>
    );

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay:true,
      autoplaySpeed:10000

    };
    return (
      <div>
        <Slider {...settings}>
          {CarouselItems}
        </Slider>
      </div>
    );
  }
}

export default SliderComponent;