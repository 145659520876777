import React from 'react';


const experienceComponent = (props) => {


    return (
        <div className="card experience">
                <div className="card-content">
                    <div className="row">
                        <div className="col s12 m2"><a href={props.information.experienceComponent.link} target="_blank" rel="noopener noreferrer"><img alt="WeSpire logo" src={props.information.experienceComponent.companyImage} className="responsive-img center-block" /></a></div>
                        <div className="col s12 m10">
                            <p><span className="card-title"><a href={props.information.experienceComponent.link} target="_blank"  rel="noopener noreferrer" className="teal-text hoverline">{props.information.experienceComponent.companyName}</a></span></p>
                        </div>
                    </div>
                    <div className="role brown-text">{props.information.experienceComponent.title}</div>
                    <p><em className="grey-text">{props.information.experienceComponent.underTitleText}</em></p>
                    {props.children}
                </div>
                <div className="card-action"><span>{props.information.experienceComponent.endingText}</span></div>
            </div>
    );

};

export default experienceComponent;