import React from 'react';

import ByuhPic from '../../../assets/images/byuh.png';
import IboatsPic from '../../../assets/images/iboats.png';


import ExperienceSection from '../PageComponent/ExperienceSection/ExperienceSection';

const experience = (props) => {

    
    
    const ExperienceComponent0startProps = {
        link: "http://www.iboats.com",
        companyImage: IboatsPic,
        companyName: "iBoats",
        title: "Cross Stack Developer",
        underTitleText: "The Responsibilities of a Cross Stack Developer is to be able to create solutions for any framework or codebase maintained by iBoats.",
        endingText: "September 2019 - Present | Taylorsville, UT"
    };

    const ExperienceComponent0Props = {
        timeline: "September 2019 - Present" ,
        experienceComponent: ExperienceComponent0startProps
    }
    const ExperienceComponent1startProps = {
        link: "http://www.byuh.edu",
        companyImage: ByuhPic,
        companyName: "BYU-Hawaii",
        title: "Software Developer",
        underTitleText: "The role of the Enterprise Information Systems department is to develop software solutions to meet the needs of the campus base of customers.",
        endingText: "March 2018 - September 2019  | Laie, HI"
    };

    const ExperienceComponent1Props = {
        timeline: "March 2018 - Present" ,
        experienceComponent: ExperienceComponent1startProps
    }

    const ExperienceComponent2startProps = {
        link: "http://www.byuh.edu",
        companyImage: ByuhPic,
        companyName: "BYU-Hawaii",
        title: "Virtual Reality Developer",
        underTitleText: "Develop a Virtual Reality application that allows biology students to better conceptualize the formation of molecules.",
        endingText: "December 2016 – March 2018 | Laie, HI"
    };

    const ExperienceComponent2Props = {
        timeline: "December 2016 - March 2018" ,
        experienceComponent: ExperienceComponent2startProps
    }

    const ExperienceComponent3startProps = {
        link: "http://www.byuh.edu",
        companyImage: ByuhPic,
        companyName: "BYU-Hawaii",
        title: "Web & Design Specialist",
        underTitleText: "Build web pages and functionality for other departments within BYU-Hawaii.",
        endingText: "February 2016 - December 2016 | Laie, HI",
    };

    const ExperienceComponent3Props = {
        timeline: "February 2016 - December 2016" ,
        experienceComponent: ExperienceComponent3startProps
    }





    return (
        <div>

            <div className="ImportantHeader">
                <h1>Experience</h1>
            </div>
            <ul style={{padding:'0'}}>

            <ExperienceSection 
                information={ExperienceComponent0Props}
                >
                    <h6>Develop Products</h6>
                <ul>
                    <li>Troubleshoot current AWS infrastructure</li>
                    <li>Work on using CloudFlare as CDN and DDOS protection for iboats.com</li>
                    <li>Set-up AWS WAF and ACL as bot mitigation</li>
                    <li>Fixed issues on Magento Platform for better stability for customers</li>
                    <li>Integrate custom solutions from vBulletin to Xenforo</li>
                    <li>Implement a Static site that is downloaded during build via S3 to keep Image lean</li>
                    <li>Develop Python Cron to check hashes on Directory or files that integrates with AWS CLI</li>
                    <li>Fixed issues on Drupal 7</li>
                    <li>Created a way to display data meanifully on React application</li>
                    <li>Manage local user and roles via Redux,Redux Sagas and GraphQL</li>

                    
                </ul>
                <h6>Accomplishments</h6>
                <ul>
                    <li>Acting office IT Administrator</li>
                    <li>Implement ECS Cluster for Middleware</li>
                    <li>Fix AWS ECS issues and Codepipeline problems</li>
                    <li>Migrate from vBulletin to Xenforo</li>
                    <li>Protected vBulletin site by setting up a solution with AWS ECS and Python</li>
                    <li>Created a more stable environment for Drupal while making sure everything was up to date.</li>
                    <li>Created React Application to be used internally.</li>
                </ul>
                </ExperienceSection>
            
                <ExperienceSection 
                information={ExperienceComponent1Props}
                >
                    <h6>Develop Products</h6>
                <ul>
                    <li>Moved about 100 sites into static sites</li>
                    <li>Moved static sites into Git repos/deployed on server</li>
                    <li>Created Python scripts to help maintain updates for static sites</li>
                    <li>Set up temporary Drupal 8 server to use while switching over to Drupal 8</li>
                    <li>Developed JavaScript/JQuery custom pages on <a href="https://holokai.byuh.edu">Holokai</a> site</li>
                    <li>Worked on Drupal 8 sites</li>
                    <li>Develop Directory in PHP/HTML/CSS</li>
                    <li>Develop Directory in .NET/Docker</li>
                    <li>Worked on application in Docker (PHP, Python)</li>
                    <li>Worked on exisitng Java(Spring) applications</li>
                    <li>Created student development environment to help with Local development</li>
                    
                    
                </ul>
                <h6>Accomplishments</h6>
                <ul>
                    <li>Moved Drupal sites from un-patched server's to static server, which increased reliability while switching over to the new system.</li>
                    <li>Setup a Git system so that users could push edits and it would show on the website, allowing edits to be made in a timely manner while still restricting access on the server.</li>
                    <li>Created custom Python scripts to boost efficiency of edits.</li>
                    <li>Created a custom in house JavaScript/JQuery script to give functionality not provided by Drupal.</li>
                    <li>Worked on containerizing applications to make them more effecient.</li>

                </ul>
                </ExperienceSection>

                <ExperienceSection 
                information={ExperienceComponent2Props}
                >
                    <h6>Develop Products</h6>
                <ul>
                    <li>Create a Virtual Reality application utilizing Unreal Engine 4 and the HTC Vive.</li>
                    <li>Learn Unreal Engine with given resources and apply newly learned techniques to the project.</li>
                    <li>Lead a development team and be able to implement an Agile development cycle to fit our team’s needs.</li>
                    <li>Design and implement a system based on specifications given by a product owner. </li>
                    <li> Work as part of a team to provide a Virtual Reality application to aid in teaching biology concepts.</li>
                </ul>
                <h6>Accomplishments</h6>
                <ul>
                    <li>Choose a game engine to meet our given design specs.</li>
                    <li>Learned Unreal Engine and was able to learn and implement new functionality in the project.</li>
                    <li>As team leader, provided a development cycle and set of tasks for team members to accomplish.</li>
                    <li>Provided a working Virtual Reality Application within Unreal Engine to Professor to use with students to help teach molecular biology.</li>

                </ul>
                </ExperienceSection>

                <ExperienceSection 
                information={ExperienceComponent3Props}
                >
                    <h6>Develop Products</h6>
                        <ul>
                            <li>Follow design documents and utilize PHP, JavaScript, jQuery, HTML/CSS, and Bootstrap to build web pages.</li>
                            <li>Work on LAMP stack to bring real time updates and fixes to web server.</li>
                            <li>Utilize Drupal as a CMS, maintain and update Drupal, and deal with server issues as needed.</li>
                            <li>Utilize PhoneGap and Intel XDK to create a multiplatform mobile application for clients.  </li>
                        </ul>
                        <h6>Accomplishments</h6>
                        <ul>
                            <li>Learn tools to build web pages with standard web technology.</li>
                            <li>Learn maintenance and debugging on LAMP stack.</li>
                            <li>Learned popular CMS and how to implement new features if needed with PHP.</li>
                            <li>Used PhoneGap for mobile application. learned about feeds, geolocation and notifications.</li>
                        </ul>
                </ExperienceSection>
                

                
            </ul>

        </div>

    )
};

export default experience;