import React from 'react';

// import {  Link } from 'react-router-dom';

import Logo from '../../Logo/Logo';

import Github from '../../../assets/images/github.png';
import Email from '../../../assets/images/email.png';
import Resume from '../../../assets/images/resume.png'
import Linkedin from '../../../assets/images/linkdin.png';

import ResumeLink from '../../../assets/Resume/Web Resume Scott Cooper.pdf';


const sideNavBar = (props) => {


    return (
        <nav id="sidebar">

            <div className="sidebar-header">
                <Logo />
                <h3>Scott Cooper</h3>
            </div>

            <ul className="list-unstyled components">
                <p>Software Developer</p>
                <li className="active">
                    {/* <Link to="/" data-toggle="collapse" aria-expanded="false" >Home / Portfolio</Link> */}
                    <a href="/"  >Home / Portfolio</a>
                </li>
                <li>
                {/* <Link to="/aboutme" >About Me</Link> */}
                    <a href={ResumeLink} target="_blank"  rel="noopener noreferrer">Resume</a>
                </li>
                <li>
                {/* <Link to="/experience" >Experience</Link> */}
                    <a href="/experience">Experience</a>
                </li>
                <li>
                    {/* <Link to="/skills" >Skills</Link> */}
                    <a href="/skills" >Skills</a>
                </li>
                <li>
                {/* <Link to="/aboutme" >About Me</Link> */}
                    <a href="/aboutme">About Me</a>
                </li>
                
                <li>
                {/* <Link to="/contact" >Contact Information</Link> */}
                    <a href="/contact">Contact</a>
                </li>
                <li className="icon-set">
                        <a href="mailto:sgcooper78@gmail.com" ><img alt="Email Icon" src={Email}/></a>
                        <a href="https://github.com/sgcooper78" target="_blank"  rel="noopener noreferrer"><img alt="Github Icon" src={Github}/></a>
                    <a href="https://www.linkedin.com/in/sgcooper78/" target="_blank"  rel="noopener noreferrer"><img alt="Linkedin Icon" src={Linkedin}/></a>
                    <a href={ResumeLink} target="_blank"  rel="noopener noreferrer"><img alt="Resume Icon" src={Resume}/></a>
                    
                    
                </li>
            </ul>

        </nav>
    );
};

export default sideNavBar;